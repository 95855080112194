
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}


.opener-btn {
  background-color: #4a62c3;
  border: none; 
  color: white; 
  padding: 15px 32px;
  text-align: center;
  text-decoration: none; 
  display: inline-block;
  font-size: 16px; 
  font-weight: bold;
  border-radius: 12px; 
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.opener-btn:hover {
  background-color: #4a62c3; 
  transform: translateY(-2px); 
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}
